import PropTypes from 'prop-types'
import React, {useContext, useEffect} from 'react'
import {useDispatch} from 'react-redux'

import {navigate} from '@redux/slices/navigation-slice'
import {LanguageContext} from '@utils/context'
import {getLang} from '@utils/i18n'
import {CommonPageContext, CommonProps} from '@utils/types'

/**
 * LanguageRedirect
 */
interface LanguageRedirectContext extends CommonPageContext {
	redirectPath: string
}

interface LanguageRedirectProps extends CommonProps {
	pageContext: LanguageRedirectContext
}

const LanguageRedirect: React.FC<LanguageRedirectProps> = ({
	pageContext,
	location,
}) => {
	const {redirectPath} = pageContext
	const {activeLanguage} = useContext(LanguageContext)

	const dispatch = useDispatch()

	useEffect(() => {
		const searchParams = location!.search
		dispatch(
			navigate(`/${activeLanguage}/${redirectPath}${searchParams}`, {
				replace: true,
			})
		)
	}, [location!.search, redirectPath, activeLanguage])

	return <div />
}

LanguageRedirect.propTypes = {
	pageContext: PropTypes.any.isRequired,
	location: PropTypes.any,
}

export default LanguageRedirect
